import React, { useEffect, useState } from "react"
import { Group } from "@visx/group"
import { curveMonotoneX } from "@visx/curve"
import { LinePath } from "@visx/shape"
import { scaleLinear } from "@visx/scale"
import { AxisLeft, AxisBottom } from "@visx/axis"
import { GridRows, GridColumns } from "@visx/grid"
import styled from "styled-components"
// @ts-ignore
import useDimensions from "react-use-dimensions"
import { useWindowWidth } from "../../utils/useWindowWidth"

const defaultMargin = { top: 60, right: 40, bottom: 40, left: 81 }

interface ChartProps {
  width?: number
  height?: number
  margin?: { top: number; right: number; bottom: number; left: number }
  data: Datapoint[]
  label?: string
}

interface Datapoint {
  year: number
  cases: number
}

export interface CountyData {
  search: string
  values: Datapoint[]
}
export type CasesData = CountyData[]

export const Chart = ({
  width = 820,
  height = 390,
  margin = defaultMargin,
  data = [],
  label = "Incidence",
}: ChartProps) => {
  const [ref, dims] = useDimensions()

  if (dims.width < 10) return null
  const background = "transparent"

  // accessors
  const date = (d: Datapoint) => d.year
  const cases = (d: Datapoint) => d.cases

  // domains
  const timeDomain = [Math.min(...data.map(date)), Math.max(...data.map(date))]
  const casesDomain = [0, Math.max(...data.map(cases))]
  // scales
  const timeScale = scaleLinear<number>({
    domain: timeDomain,
  })
  const casesScale = scaleLinear<number>({
    domain: casesDomain,
    nice: true,
  })
  // bounds
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom
  timeScale.range([0, xMax])
  casesScale.range([yMax, 0])
  const w = useWindowWidth()

  return (
    <SVG
      ref={ref}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      className="cases-chart"
      style={{
        padding: w > 768 ? 42 : 10,
        marginLeft: w > 768 ? -72 : -35,
        maxWidth: w - 20,
        maxHeight: w / 2,
      }}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={background}
        rx={14}
      />
      <Group left={w > 768 ? margin.left : margin.left + 10} top={margin.top}>
        <g className="grid">
          <GridRows
            numTicks={4}
            scale={casesScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <GridColumns
            numTicks={15}
            scale={timeScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
        </g>
        <AxisBottom
          top={yMax}
          scale={timeScale}
          tickValues={[2010, 2015, 2020, 2025]}
          hideTicks
          tickFormat={d => d.toString()}
        />
        <AxisLeft
          scale={casesScale}
          numTicks={5}
          // hideAxisLine
          hideTicks
        />
        <text x={"-80"} y="-30" className="y-axis-label">
          {label}
        </text>
        <LinePath
          data={data}
          curve={curveMonotoneX}
          x={d => timeScale(date(d)) || 0}
          y={d => casesScale(cases(d)) || 0}
          stroke="#54DD8B"
          strokeWidth={4}
          strokeOpacity={1}
          strokeDasharray="1,2"
        />
      </Group>
    </SVG>
  )
}

const SVG = styled.svg`
  text {
    font-size: 3em;
    font-family: Gentona-Light;
    font-size: 20px;

    @media (min-width: 768px) {
      font-size: 30px;
    }
  }

  .visx-axis-bottom text {
    transform: translateY(15px);
  }

  .visx-axis-left text {
    transform: translateX(-15px);
  }

  .grid line {
    opacity: 0.4;
    stroke: #1b4942;
    stroke-dasharray: 2 2;
  }

  .grid g line:first-child,
  .grid g line:last-child {
    opacity: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    text {
      font-size: 2.4em;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    text {
      font-size: 2.4em;
    }
  }

  .y-axis-label {
    font-size: 1em;
  }
`

interface IItem {
  label: string
}

export const isMatchingInput = (state: IItem, value: string) => {
  return (
    state.label.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
  )
}
export function sortStates(a: IItem, b: IItem, value: string) {
  const aLower = a.label.toLowerCase()
  const bLower = b.label.toLowerCase()
  const valueLower = value.toLowerCase()
  const queryPosA = aLower.indexOf(valueLower)
  const queryPosB = bLower.indexOf(valueLower)
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB
  }
  return aLower < bLower ? -1 : 1
}

import { Box, Flex, Heading, Text } from "rebass/styled-components"
import { theme } from "../../theme"
import { data } from "../homepage/data"
import { TickIcon } from "../icons/Man"
import { Chart, CountyData } from "../homepage/Chart"
import * as React from "react"
import styled from "styled-components"

interface ILocalDetails {
  selectedDataPoint: CountyData
  variant?: "storymap"
}

export const LocalDetails = ({ selectedDataPoint, variant }: ILocalDetails) => {
  const isStorymap = variant === "storymap"

  const getChartTitle = () => {
    const firstYear = selectedDataPoint.values[0].year
    const lastYear =
      selectedDataPoint.values[selectedDataPoint.values.length - 1].year

    return `${data.search.chartTitle}, ${firstYear}-${lastYear}`
  }

  return (
    <Flex style={{ position: "relative", overflow: "hidden" }} flexWrap="wrap">
      <Flex
        width={isStorymap ? [1, 1, 1, 5 / 12] : [1, 1, 5 / 12]}
        px={isStorymap ? [0] : [4, 4, 4]}
        justifyContent="flex-end"
        alignItems="center"
        bg={isStorymap ? "white" : "#1B4942"}
      >
        <Flex
          height={"100%"}
          width={
            isStorymap
              ? [1, 1, 1, (5 / 12) * theme.contentWidth]
              : [1, 1, (5 / 12) * theme.contentWidth]
          }
          mr={0}
          pr={[0, 0, 4]}
          py={5}
          flexDirection="column"
          justifyContent={"space-between"}
        >
          <Heading color={theme.colors.link} mb={4}>
            {selectedDataPoint.search}
          </Heading>

          {data.search.kpiInsights.map(text => (
            <Grid key={text}>
              <TickIcon size={24} />
              <Text
                color={isStorymap ? theme.colors.text : theme.colors.textWhite}
                fontSize={isStorymap ? "16px" : "initial"}
                lineHeight={isStorymap ? "24px" : "initial"}
                mb={3}
              >
                {text}
              </Text>
            </Grid>
          ))}
        </Flex>
      </Flex>
      <Flex
        width={isStorymap ? [1, 1, 1, 7 / 12] : [1, 1, 7 / 12]}
        px={isStorymap ? [0] : [4, 4, 4]}
        bg={isStorymap ? "white" : theme.colors.bgLight}
        justifyContent="flex-start"
        alignItems={"flex-start"}
        pt={isStorymap ? [0, 0, 5] : 5}
        pb={5}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          width={
            isStorymap
              ? [1, 1, 1, (7 / 12) * theme.contentWidth]
              : [1, 1, (7 / 12) * theme.contentWidth]
          }
          mr={0}
          pl={[1, 1, 1, 5]}
        >
          <Heading
            fontSize={isStorymap ? ["17px", "17px", "18px"] : "18px"}
            fontFamily="Gentona-Book"
          >
            {getChartTitle()}
          </Heading>
          <Flex my={isStorymap ? [-5, 0] : "initial"}>
            <Chart data={selectedDataPoint.values} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
`
